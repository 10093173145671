
import { useEcharts } from "@/hooks";
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { dispose, graphic } from "echarts";
import { random } from "lodash";
const months = [
  "迭代中",
  "迭代中",
  "迭代中"
];
function getData() {
  const data: number[] = [];
  while (data.length < months.length) {
    data.push(random(100, 150));
  }
  return data;
}
export default defineComponent({
  name: "FullYearSalesChart",
  setup() {
    const loading = ref(true);
    const fullYearSalesChart = ref<HTMLDivElement | null>(null);
    let interval: any = null;
    const init = () => {
      const option = {
        color: ["rgba(64, 58, 255)"],
        grid: {
          top: "10%",
          left: "0%",
          right: "0%",
          bottom: "46px",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: 'category',
          data: months,
          show: true, // 确保轴本身是可见的
          axisLine: {
            show: false, // 设置为 false 以隐藏轴线
          },
          axisTick: {
            show: false, // 设置为 false 以隐藏轴刻度
          },
          axisLabel: {
            show: true, // 确保轴标签是可见的
          },
        },
        yAxis: {
          show: false,
          type: "value",
          max: 200,
        },
        series: [
          {
            type: "bar",
            stack: "总量",
            data: getData().map((item, index) => {
              let color;
              // 根据 index 选择颜色
              switch (index % 3) {
                case 0:
                  color = "#0066FF";
                  break;
                case 1:
                  color = "#FFE27A";
                  break;
                case 2:
                  color = "#9BFF9F";
                  break;
              }
              return {
                value: item,
                itemStyle: {
                  color: color, // 设置这个数据点的颜色
                  borderRadius: [5, 5, 0, 0]
                },
              };
            }),
            barWidth: '32%',
            label: {
              show: false
            },
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" },
              ]),
            },
          },
        ],
      };
      setTimeout(() => {
        loading.value = false;
        nextTick(() =>
          useEcharts(fullYearSalesChart.value as HTMLDivElement).setOption(
            option
          )
        );
      }, 1000);
    };
    const updateChart = () => {
      useEcharts(fullYearSalesChart.value as HTMLDivElement).resize();
    };
    onMounted(init);
    return {
      loading,
      fullYearSalesChart,
      updateChart,
    };
  },
});
